import React from "react";
import {baseUrl} from "../stores/apiStore"

class WSListener extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ws: new WebSocket(baseUrl.replace("http", "ws") + "/ws/")
    }
  }

  componentDidMount() {
    this.state.ws.onopen = () => {
      console.log("opened");
      this.state.ws.send(this.props.id);
    }
    this.state.ws.onmessage = (e) => {
      console.log("message", e.data);
      this.props.onUpdate();
    }
  }

  render() {
    return <p>WS Listener Object</p>;
  }
}
export default WSListener;

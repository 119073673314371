import React, {useEffect} from 'react';
// import {LazyLog, ScrollFollow} from 'react-lazylog';
import AceEditor from "react-ace";
import {Button, Container} from "react-bootstrap";

const ViewLogs = ({children, onUpdate}) => {
    // const [didUpdate, setUpdate] = useState(false);
    // const text = children ? children[children.length - 1].text : ''
    const text = children ? children.reverse().map(child => child.text).join('\n') : '';
    const position = (children && children.length) ? children[children.length - 1].position : 0;

    useEffect(() => {
        onUpdate({current_position: position});
    }, [position, onUpdate])

    return <Container>
        <AceEditor
            value={text}
            width="90vw"
            style={{boxShadow: '0 3px 7px 0 rgba(0, 0, 0, 0.2), 0 4px 12px 0 rgba(0, 0, 0, 0.19)'}}
            name="logs"
            theme="terminal"
            fontSize={14}
            showGutter={false}
            readOnly={true}
        />
        <Button size="lg" variant="dark" onClick={() => {
            onUpdate({current_position: position});
        }}>
            🔄
        </Button>
    </Container>
};

export default ViewLogs;
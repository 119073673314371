import React, {useState} from "react";
import {Row, Button} from "react-bootstrap";
import {COLORS} from "../constants";

const BUTTON_SIZE = 45;
const BUTTON_MARGIN = 2;
const BUTTON_SIZE_WITH_MARGIN = BUTTON_SIZE + BUTTON_MARGIN;

const DEFAULT_ON_CHOOSE = (data) => {};

const TalpiotGradePicker = ({default_value = null, color_map = {}, onChoose = DEFAULT_ON_CHOOSE}) => {
    const [grade, setGrade] = useState(default_value);
    const [hover, setHover] = useState(null);

    const onClickButton = (index) => {
        setGrade(index);
        onChoose({chosen: index});
    }

    return <Row style={{alignItems: 'center', justifyContent: 'space-between', margin: 0,
        width: `${1.1 * BUTTON_SIZE * Object.keys(color_map).length}px`, height: `${BUTTON_SIZE_WITH_MARGIN + 1}px`,}}>
        {
            Object.keys(color_map).map((num) => (
                <Button
                    key={num}
                    style={{
                        backgroundColor: grade === num ? color_map[num] : COLORS.WHITE,
                        color: grade === num ? COLORS.WHITE : color_map[num],
                        opacity: hover === num || grade === num ? 1 : 0.3,
                        fontSize: '20px',
                        border: `2px solid ${color_map[num]}`,
                        borderRadius: '50%',
                        boxShadow: `0 0 10px 0 #888`,
                        width: `${hover === num ? BUTTON_SIZE_WITH_MARGIN : BUTTON_SIZE}px`,
                        height: `${hover === num ? BUTTON_SIZE_WITH_MARGIN : BUTTON_SIZE}px`
                    }}
                    onClick={() => {onClickButton(num)}}
                    onMouseEnter={() => {setHover(num)}}
                    onMouseLeave={() => {setHover(null)}}
                >
                    {num}
                </Button>)
            )}
    </Row>

}

export default TalpiotGradePicker;
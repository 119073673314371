import React, {useContext} from "react";
import {Redirect} from "react-router-dom";
import {Container, Button} from "react-bootstrap";
import {userContext} from "../stores/userContext";
import GoogleLogin from "react-google-login";
import {reactUrl} from "../stores/apiStore";
import {COLORS} from "../constants";
import LoadingAlert from "./LoadingAlert";
import HebrewLabel from "./HebrewLabel";

const LoginComponent = () => {
    const {user, logoutUser} = useContext(userContext);
    const isLoggedOut = Object.keys(user).length === 0;
    const loginMessage = isLoggedOut ? 'התחבר/י' : 'התנתק/י'

    const loggedInButton =
        <Button onClick={logoutUser} variant="outline-light" style={{backgroundColor: COLORS.TALPIOT_CYAN}}>
            {loginMessage}
        </Button>

    const loggedOutButton = <GoogleLogin
        clientId="330244991572-iimj6mtoao2motr1c05oklic54oo50vb.apps.googleusercontent.com"
        buttonText={loginMessage}
        uxMode='redirect'
        redirectUri={reactUrl + '/login'}
        cookiePolicy={'single_host_origin'}
        style={{width: 'fit-content'}}
    />
    return <Container style={{width: 'fit-content', padding: '0 5vh'}} dir="ltr">
        {isLoggedOut ? loggedOutButton : loggedInButton}
    </Container>
};

const LoginParseRedirectFromGoogle = ({user, loginUser, isDev}) => {
    if (Object.keys(user).length === 0) { // if user doesn't exist
        let afterhash = window.location.hash.substr(1);
        let key = afterhash.split("id_token=")[1].split("&login_hint=")[0];

        loginUser({tokenId: key, isDev:isDev});

        return <Container>
            <h1>מתחבר לשרת</h1>
            <Container style={{width: '50%'}}>
                {LoadingAlert}
            </Container>
            <HebrewLabel color="#888">לא אמור לקחת יותר מ5 שניות</HebrewLabel>
        </Container>;

    } else {
        if (isDev){
            return <Redirect to="/react-dev"/>;
        }
        else {
            return <Redirect to="/react"/>;
        }
    }
};

export {LoginParseRedirectFromGoogle};

export default LoginComponent;
import React from "react";
import talpix_logo from "./talpiot-logo.png"
import yesodot_logo from "./yesodot-logo.png"


const TalpiotLogo = ({size_type, logo}) => {
    const styleImage = {
        width: getLogoSize(size_type), alignSelf: 'center', padding: '5px'
    } //, top: window.innerHeight*0.1
    return <img src={logo} alt="Talpiot" style={styleImage}/>
}


const getLogoSize = (size_type) => {
    let size = '1';
    if (size_type === "page")
        size = '25'
    if (size_type === "symbol")
        size = '4'

    if (window.innerHeight > window.innerWidth)
        size = `${size}vh`
    else
        size = `${size}vw`
    return size;
}

export const TalpixLogo = ({size_type}) => {
    return <TalpiotLogo size_type={size_type} logo={talpix_logo}/>
};

export const YesodotLogo = ({size_type}) => {
    return <TalpiotLogo size_type={size_type} logo={yesodot_logo}/>
};


import React from 'react';
import Button from "./Button";

import { Buffer } from "buffer";


const DownloadButton = ({text, onDownload, name: filename, filepath, ...props}) => {
    return <Button
        text={text}
        onClick={() => {
            onDownload().then((response) => {
                const data_bytes = Buffer.from(response.data, "base64");
                const url = window.URL.createObjectURL(new Blob([data_bytes]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        );}}
        {...props}>
    </Button>
};

export default DownloadButton;
import React from 'react';
import axios from 'axios';
import {baseDevUrl, baseUrl} from './apiStore';


/**
 * User login logic, those functions are static
 */


const userContext = React.createContext({user: {}});

export const getJWT = (googleResponse) => {
	let tokenId = googleResponse.tokenId;
    const url = googleResponse.isDev? `${baseDevUrl}/api/token/` : `${baseUrl}/api/token/`;
	return axios.post(url, { token: tokenId })
		.then(response => response.data)
		.then(token => {
            return getUser(token.access, googleResponse.isDev).then(
                result => {
                    var user = result.user;

                    user.access = token.access;
                    user.refresh = token.refresh;

                    return user;
                }
            )
        }).catch(error => {
            console.log("Error: User does not match!", error);
            return {};
        })
};

export const getJWTMicrosoft = ({response, isDev}) => {
    const url = isDev? `${baseDevUrl}/api/ms_token/` : `${baseUrl}/api/ms_token/`;
	return axios.post(url, { token: response["email"] })
		.then(response => response.data)
		.then(token => {
            return getUser(token.access, isDev).then(
                result => {
                    var user = result.user;

                    user.access = token.access;
                    user.refresh = token.refresh;

                    return user;
                }
            )
        }).catch(error => {
            console.log("Error: User does not match!", error);
            window.alert("נסו להיכנס דרך מייל קליק");
            return {};
        })
};

export const refreshJWT = (refreshToken) => {
	return axios.post(`${baseUrl}/api/token/refresh/`, { refresh: refreshToken })
		.then(response => response.data)
		.then(token => {
            // TODO: pass isDev
            return getUser(token.access, false).then(
                result => {
                    var user = result.user;

                    user.access = token.access;
                    user.refresh = refreshToken;

                    return user;
                }
            )
        }).catch(error => {
            return {};
        })
};

export const getUser = (token, isDev) => {
	let headers = {
		'Authorization': 'Bearer ' + token
	}
    const url = isDev? `${baseDevUrl}/api/token/user/get/` : `${baseUrl}/api/token/user/get/`;
	return axios.get(url, {headers: headers})
		.then(response => response.data);
};


export { userContext };
import React, {useContext} from "react";
import {Container} from "react-bootstrap";
import {userContext} from "../stores/userContext";
import MicrosoftLogin from "./MicrosoftLogin";
import {baseUrl, baseDevUrl, reactUrl} from "../stores/apiStore";
import axios from "axios";

const MicrosoftLoginComponent = ({isDev}) => {
    const {user, loginMicrosoftUser} = useContext(userContext);
    const isLoggedOut = Object.keys(user).length === 0;

    const loginHandler = (err, data, msal) => {
        console.log(err, data, msal);
        const url = isDev? `${baseDevUrl}/api/ms-user` : `${baseUrl}/api/ms-user`;
        axios.post(url, data)
            .then(response => {
                console.log("response: ",response);
                loginMicrosoftUser({response:response["data"], isDev: isDev})
            })
            .catch(error => {
                console.error(error);
            });
    };
    const loggedOutButton = <MicrosoftLogin
        clientId={'e8e39dee-0577-418b-b40c-552ae9d690e1'}
        authCallback={loginHandler}
        graphScopes={['openid', 'User.ReadBasic.All', 'email']}//, 'Mail.send', 'Mail.send.Shared', 'Calendars.ReadWrite.Shared', 'Files.ReadWrite.All']}
        redirectUri={reactUrl + '/microsoft_login'}
        postLogoutRedirectUri={reactUrl + '/microsoft_signout'}
        buttonTheme={'light_short'}
    />
    return <Container style={{width: 'fit-content', padding: '0 5vh'}} dir="ltr">
        {isLoggedOut ? loggedOutButton : null}
    </Container>
};

export default MicrosoftLoginComponent
import React, {useState} from 'react';
import {Col, Row} from "react-bootstrap"
import HebrewLabel from "./HebrewLabel";
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";

const HEBREW_TIMES = {
    seconds: 'שניות',
    minutes: 'דקות',
    hours: 'שעות',
    days: 'ימים',
}

const CountDown = ({time: countDownTime, size: numberSize = 'md'}) => {
    const [countSeconds, setCountSeconds] = useState(0);
    const [countMinutes, setCountMinutes] = useState(0);
    const [countHours, setCountHours] = useState(0);
    const [countDays, setCountDays] = useState(0);
    const updateCount = () => {
        setTimeout(() => {
            const distance = new Date(countDownTime).getTime() - new Date().getTime();
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);
            setCountSeconds(seconds);
            setCountMinutes(minutes);
            setCountHours(hours);
            setCountDays(days);
            updateCount();
        }, 500);
    }

    updateCount();
    return <Row dir="rtl">
        {Object.entries({
            days: countDays,
            hours: countHours,
            minutes: countMinutes,
            seconds: countSeconds
        }).map(([key, value]) => {
            let maxValue = 60;
            if (key === 'hours')
                maxValue = 24;
            else if (key === 'days')
                maxValue = 30;
            let relativeColor = Math.floor(value / maxValue * GRADIENT_ARRAY.length);
            relativeColor = relativeColor >= GRADIENT_ARRAY.length ? GRADIENT_ARRAY.length - 1 : relativeColor;
            return <Col>
                <CircularProgressbar
                    value={value}
                    text={value.toString().padStart(2, '0')}
                    strokeWidth="5"
                    round={true}
                    minValue={0}
                    maxValue={maxValue}
                    styles={buildStyles({
                        textSize: '2rem',
                        textColor: '#000',
                        pathColor: GRADIENT_ARRAY[relativeColor],
                        rotation: 0.5,
                        strokeLinecap: 'round',
                        fontSize: numberSize
                    })}
                />
                <HebrewLabel size="2rem">{HEBREW_TIMES[key]}</HebrewLabel>
            </Col>
        })}
    </Row>
};

export default CountDown;

const GRADIENT_ARRAY = [
    "#006200","#096603","#126A06","#1A6E0A","#23720D","#2C7710","#357B13","#3E7F16","#468319","#4F871D","#588B20",
    "#618F23","#6A9326","#729729","#7B9B2C","#84A030","#8DA433","#95A836","#9EAC39","#A7B03C","#B0B43F","#B9B843",
    "#C1BC46","#CAC049","#D3C44C","#DCC94F","#E5CD52","#EDD156","#F6D559","#FFD95C",
    "#FFD95C","#FFD259","#FFCA56","#FFC352","#FFBB4F","#FFB44C","#FFAC49","#FFA546","#FF9D43","#FF963F","#FF8E3C",
    "#FF8739","#FF7F36","#FF7833","#FF7030","#FF692C","#FF6129","#FF5A26","#FF5223","#FF4B20","#FF431D","#FF3C19",
    "#FF3416","#FF2D13","#FF2510","#FF1E0D","#FF160A","#FF0F06","#FF0703","#FF0000","#F00000"
]


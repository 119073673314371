import React, {useEffect, useState} from 'react';
import {DataGrid, GridToolbar, heIL} from '@mui/x-data-grid';
import {Button} from "@mui/material";
import {COLORS} from "../constants";


const DEFAULT_SUBMIT = (data) => {
};

const TableForm = ({data = [], headers = [], onSubmit = DEFAULT_SUBMIT, text: button_text, background_color}) => {
    const [rows, setRows] = useState(data);
    useEffect(() => { setRows(data); }, [data]);

    const columns = headers.map((header) => ({
        ...header,
        align: 'left',
        headerAlign: 'left',
        minWidth: 1000 / headers.length
    }));

    return <div>
        <DataGrid
            rows={rows}
            columns={columns}
            style={{width: '70vw', direction: 'rtl', backgroundColor: background_color || COLORS.TRANSPARENT}}
            localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
            slots={{
                toolbar: GridToolbar,
            }}
            processRowUpdate={(newRow) => {
                const newRows = rows.map(row => row.id === newRow.id ? newRow : row);
                setRows(newRows);
                return newRow;
            }}
            onProcessRowUpdateError={(params) => {
                console.log("ERROR", params);
                return params;
            }}
        >
        </DataGrid>
        {button_text &&
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    onSubmit({data: rows});
                    console.log("DATA", rows)
                }}
                style={{marginTop: '1rem'}}
            >
                {button_text}
            </Button>
        }
        <br/>
    </div>;
}

export default TableForm;

import React from "react";
import {Tab as BootstrapTab, Tabs as BootstrapTabs} from "react-bootstrap";
import ComponentSelector from "./ComponentSelector";
import {COLORS} from "../constants";


const Tab = ({children, background_color = COLORS.BLACK, text_color = COLORS.BLACK, ...props}) => {
    const headerStyle = {
        padding: '0',
        border: 'none',
        color: `${text_color} !important` || COLORS.BLACK,
        backgroundColor: background_color,
        overflow: 'hidden',
        width: '90vw'
    }
    return (
        <BootstrapTabs dir="rtl" style={headerStyle}>
            {
                children && children.map((child, index) =>
                    <BootstrapTab
                        eventKey={index + 1}
                        title={child.title}
                    >
                        {child && child.component && ComponentSelector(child.component)}
                    </BootstrapTab>
                )
            }
            <style type="text/css">
                {`
                .card-header:hover {
                    background-color: rgba(200, 200, 200, 0.3) !important;
                    cursor: pointer !important;
                }
                a {
                    color: ${text_color} !important;
                }
            `}
            </style>
        </BootstrapTabs>);
}

export default Tab;

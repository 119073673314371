import React from 'react';
import ComponentSelector from "./ComponentSelector";


const StackPanel = ({children, orientation= 0, justifyContent = 'center',
                        alignItems = 'center'}) => {
    const direction_s = orientation === 1 ? 'column' : 'row';
    const flex_container = {
        display: 'flex',
        flexDirection: direction_s,
        alignItems: alignItems,
        justifyContent: justifyContent,
        height: 'fit-content'
    };

    return <div style={flex_container} id="StackPanel" dir="rtl">
        {children && children.map(child => {
                if (!child || !child.component)
                    return null;
                const {component: componentData} = child;
                const component = ComponentSelector(componentData);
                return <div style={{margin: '2px', width: componentData.relative_width}}>{component}</div>
            }
        )}
    </div>;
};

export default StackPanel;

import React, {useId} from "react";
import useRef from "react";
import SignatureCanvas from "react-signature-canvas";
import {useEffect, useState} from 'react';
import useWindowDimensions from "../stores/useWindowDimensions";
import {Button as BootstrapButton, Spinner} from "react-bootstrap";
import {COLORS} from "../constants";


const SignatureComponent = ({onSign, component_id}) => {
  const {width, height} = useWindowDimensions();

  console.log(width, height);
  const clear = () => {
    signaturePad.clear();
    updateSubmitButton();
  }

  const updateSubmitButton = () => {
    document.getElementById(btn_id).disabled = !!signaturePad.isEmpty();
  }

  const btnStyle = {
    color: COLORS.WHITE,
    backgroundColor: COLORS.TALPIOT_CYAN,
    padding: '0.35rem 0.8rem',
    minWidth: 'fit-content',
    height: 'fit-content',
    width: 'min-content',
    alignItems: 'center',
    alignText: 'center',
  }

  const onSubmit = () => {
    if (signaturePad.isEmpty()) {
      return;
    }
    console.log("submitting");
    onSign({img_data: signaturePad.toDataURL()});
  }

  useEffect(() => {updateSubmitButton()}, [updateSubmitButton]);

  let signaturePad = {};
  let btn_id = component_id + "accept";
  // const signaturePad = new SignaturePad(canvas);
  return <div style={{width: "100%", alignItems: "center", display: "flex", flexDirection: "column"}}>
    <div style={{border: "1px solid blue", width: "min-content"}}>
      <SignatureCanvas id={"signature canvas"} penColor='black'
                       canvasProps={{width: Math.min(500, width - 20), height: 200, className: 'sigCanvas'}}
                       ref={(ref) => {
                         signaturePad = ref
                       }}
                       onEnd={updateSubmitButton}
      />
    </div>
    <BootstrapButton onClick={clear} block variant="outline-light" style={btnStyle}>❌</BootstrapButton>
    <BootstrapButton block variant="outline-light" style={btnStyle} id={btn_id} onClick={onSubmit}>✔</BootstrapButton>
  </div>
}


export default SignatureComponent
;

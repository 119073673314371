import React, {useEffect, useState} from "react";
import {convertToRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import draftToHtml from 'draftjs-to-html';
import {stateFromHTML} from "draft-js-import-html";
import HebrewLabel from "./HebrewLabel";
import {FONTS} from "../constants";
import {Col} from "react-bootstrap";

const MILLISECONDS_IN_MINUTES = 60000;

const defaultOnSave = (data) => {
    console.log("Saving data: ", data);
};
const RichTextEditor = ({
                            initial_state, width = "70vw", height = "40vh", onSave = defaultOnSave,
                            last_update = null
                        }) => {
    const [editorState, setEditorState] = useState(EditorState.createWithContent(stateFromHTML(initial_state)));
    const [minutesSinceLastUpdate, setMinutesSinceLastUpdate] = useState(0);
    const [htmlData, setHtmlData] = useState(initial_state || "");

    useEffect(() => {
        const interval = setInterval(() => {
            const timedelta = new Date().getTime() - new Date(last_update).getTime();
            const minutes = Math.floor(timedelta / MILLISECONDS_IN_MINUTES);
            setMinutesSinceLastUpdate(minutes);
        }, MILLISECONDS_IN_MINUTES);
        return () => clearInterval(interval);
    }, [minutesSinceLastUpdate, last_update]);

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setHtmlData(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    return <Col>
        <div style={{width: '33%'}}>
            <HebrewLabel font={FONTS.GISHA} size="sm" color="#444">
                עודכן לאחרונה לפני {minutesSinceLastUpdate} דקות
            </HebrewLabel>
        </div>
        <div style={{width: '100%', height: '100%', justifyContent: 'center', display: 'flex'}} dir="ltr">
            <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                onBlur={() => onSave({data: htmlData})}
                style={{width: width, height: height}}
            />
            <style type="text/css">
                {`
                .rdw-editor-main {
                    background-color: #f7f7f9;
                    font: David;
                }
                .rdw-editor-wrapper {
                    height: ${height};
                    width: ${width};
                    margin-bottom: 10vh;
                }
                .rdw-editor-toolbar {
                    color: #222;
                }
                .DraftEditor-root {
                  font-size: 14px;
                }
            `}
            </style>
        </div>
    </Col>

}

export default RichTextEditor;
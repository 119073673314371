import React from 'react';
import ComponentSelector from "./ComponentSelector";

const FloatingTabView = ({children}) => {
    let tabChildren = [], contentChildren = []
    children.forEach(child => {
        if (child.type === 'tab')
            tabChildren.push(child)
        else if (child.type === 'content')
            contentChildren.push(child)
    })

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative'
    }} id='tabview-container'>
        <div id='floating-tab' style={{
            height: '50px',
            backgroundColor: 'white',
            minWidth: '300px',
            boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
            position: 'sticky',
            padding: '10px 20px 10px 20px',
            display: 'flex',
            borderRadius: '20px',
            top: '80px',
            justifyContent: 'space-between',
            alignItems: 'center'
        }}>
            {tabChildren && tabChildren.map(child => {
                    if (!child || !child.component)
                        return null;
                    const {component: componentData} = child;
                    return ComponentSelector(componentData);
                }
            )}
        </div>
        <div id='tabview-content' style={{
            width: "100%", height: "100%", marginTop: "10px"
        }}>
            {contentChildren && contentChildren.map(child => {
                    if (!child || !child.component)
                        return null;
                    const {component: componentData} = child;
                    return ComponentSelector(componentData);
                }
            )}
        </div>

    </div>
}

export default FloatingTabView;


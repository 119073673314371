import {INSTRUCTIONS} from "../constants";


class InstructionStore {
    constructor() {
        this.data = [];
        this.parentsDict = {}; // For every component, his parent
    }

    handleInstruction = (instructions, props) => {
        instructions.forEach(({instruction, value}) => {
            if (!value || !value.id)
                return;

            // Redirect to url
            if (instruction === INSTRUCTIONS.REDIRECT) {
                this.handleRedirect(value, props.history);
            }

            // Add a component to root
            if (instruction === INSTRUCTIONS.ADD) {
                this.data.push({...value});
                this.parentsDict[value.id] = [] // declare that this component does not have a parent
            }

            // All the other instructions
            if ([INSTRUCTIONS.CHANGE, INSTRUCTIONS.ADD_COMPONENT, INSTRUCTIONS.REMOVE_CHILD].includes(instruction)) {
                if (value && value.child && value.child.component) {
                    this.handleChange(value);
                }
                // Update the component
                this.doActionOnComponent(instruction, value);
            }
        })
    };

    handleRedirect = (value, history) => {
        let url;
        if (value.override) {
            url = value.url;
        } else if (!history.location.pathname.endsWith(value.url)) {
            url = `${history.location.pathname}${value.url}`.replace('//', '/');
        } else {
            return;
        }

        if (value.reload)
            window.location.href = url;
        else
            history.push(url);
    };

    handleChange = (value) => {
        // update its parent in the parent dict
        const grandParents = this.parentsDict[value.id]
        if (!grandParents) {
            console.error(`Trying to add a ${value.child.component.type} to not existing component with id ${value.id}`);
            return;
        }
        this.parentsDict[value.child.component.id] = [value.id, ...grandParents] // save the parent id of the child
    };

    doActionOnComponent = (instruction, value) => {
        const currentParentData = this.findParentOf(value.id);
        const index = this.findIndex(currentParentData, value.id);
        if (index < 0 || index >= currentParentData.length || !value)
            return;
        currentParentData[index] = this.EditDataByInstruction(currentParentData[index], instruction, value);
    }

    findIndex = (data, id) => {
        return data.findIndex(i => i.id === id); // find index where value's id === id
    }

    EditDataByInstruction = (component, instruction, value) => {
        if (instruction === INSTRUCTIONS.ADD_COMPONENT && value.child) {
            const prevChildren = component.children || []; // change the value for the specific index
            component.children = [...prevChildren, value.child];
        } else if (instruction === INSTRUCTIONS.REMOVE_CHILD && value.component) {
            const prevChildren = component.children || []; // change the value for the specific index
            const childIndex = prevChildren.findIndex(child => child.id === value.component.id)
            prevChildren.splice(childIndex, 1);
            component = {...component, children: prevChildren};
        } else if (instruction === INSTRUCTIONS.CHANGE) {
            Object.entries(value).forEach(([name, attr]) => {
                component[name] = attr;
            }); // change the value for the specific index
        }
        return component;
    }

    findParentOf = (child_id) => {
        const parentIdList = [child_id, ...this.parentsDict[child_id]]; // get the id of the parent
        let currentParentData = this.data;
        for (let i = parentIdList.length - 1; i > 0; i--) {
            let parentId = parentIdList[i]

            const index = this.findIndex(currentParentData, parentId);
            if (index < 0 || index >= currentParentData.length) {
                console.log("Parent not Found", parentId, this.data)
                continue;
            }

            if (!currentParentData[index].children) {
                currentParentData[index].children = []
            }
            currentParentData = currentParentData[index].children.map(child => child.component);

        }
        return currentParentData;
    }
}

export default new InstructionStore();

import React from "react";

const EmbeddedYouTubeVideo = ({video_id, max_width, controls, autoplay, mute, start}) => {

    const url = `https://www.youtube.com/embed/${video_id}?controls=${controls ? 1 : 0}&autoplay=${autoplay ? 1 : 0}&mute=${mute ? 1 : 0}&start=${start}`;

    return (
        <iframe
            src={url}
            width={max_width}
            frameborder="0"
            allowfullscreen=""
            style={{
                maxWidth: '100%',
                aspectRatio: '16/9',
            }}>
        </iframe>
    );
}

export default EmbeddedYouTubeVideo;

import React from 'react';

import ComboBox from "../ComboBox";

const SingleSelectFormComboBox = ({onChange, options, placeholder}) => {
    const options_dict = {};
    Object.values(options.enumOptions).forEach(({value, label}) => {
        options_dict[value] = label
    });
    return (
        <ComboBox
            placeHolder={placeholder}
            multiple={false}
            options={options_dict}
            onChoose={item => {
                onChange(item.chosen);
            }}
        />
    )
};

export default SingleSelectFormComboBox;

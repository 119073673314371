import React, {useState, useEffect} from "react";
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const STROKE_WIDTH = 15;
const TEXT_SIZE = 35;

const CircularProgress = ({value, color_map = {}, size = 100}) => {
    const [relativeValue, setRelativeValue] = useState(null);

    const numbers = Object.keys(color_map).map((num) => parseInt(num));
    const closest = numbers.reduce((prev, curr) => {
      return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
    });
    const max = Math.max(...numbers);
    const min = Math.min(...numbers);

    const setFirstRelativeValue = () => {
        setRelativeValue((value - min) / (max - min) * 100 + 1);
    }

    useEffect(() => {
        setTimeout(setFirstRelativeValue, 100);
    })

    return <div style={{width: size, height: size, margin: '3px',}}>
        <CircularProgressbar
            value={relativeValue}
            text={value}
            strokeWidth={STROKE_WIDTH}
            counterClockwise
            styles={buildStyles({
                textSize: TEXT_SIZE,
                textColor: color_map[closest],
                pathColor: color_map[closest]
            })}
        />
    </div>
}

export default CircularProgress;
import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import HebrewLabel from "../components/HebrewLabel";
import {TalpixLogo} from "./TalpixLogo"
import Carousel from "react-bootstrap/Carousel";
import {COLORS, FONTS, SIZES} from "../constants";
import {fetchApi} from "../stores/apiStore";
import Button from "../components/Button";
import ComponentSelector from "../components/ComponentSelector";
import {isMobile} from "react-device-detect";
// import CountDown from "../components/CountDown";

// const ASAK_DATE = new Date(2024, 6, 29);

const LinkButton = ({text, link}) => {
    return <Col>
        <a href={link}>
            <Button text={text} onClick={() => {
            }} font={FONTS.SECULAR_ONE} size={SIZES.md} min_height="1rem"/>
        </a>
    </Col>
}

const FrontPage = ({user, isDev}) => {
    const colors = ['#fff', COLORS.TALPIOT_CYAN, COLORS.TALPIOT_BLUE, COLORS.TALPIOT_DARK_BLUE];
    const talpixLogoContainer = <Carousel interval={3000} fade wrap style={{minHeight: '10rem'}}>
        {colors.map((color) => (<Carousel.Item>
            <TalpixLogo size_type="page"/>
            <HebrewLabel size="2.5vw" font={FONTS.GISHA} bold color={color}>אתר TalpiX</HebrewLabel>
            <div style={{height: "3vw"}}/>
        </Carousel.Item>))}
    </Carousel>

    const updateStateVariable = (url, field, variable, callback) => {
        if (variable === null && url !== null) {
            fetchApi(url, user, null, null, isDev).then(
                (result) => {
                    result = result.data && result.data[field];
                    if (result) {
                        callback(result);
                    }
                }, (error) => {
                    console.error("Got Error: ", error)
                })
        }
    }

    // QUICK ACCESS BUTTONS
    const [quickAccessPages, setPages] = useState(null);
    updateStateVariable('/quick_access_pages/', 'pages', quickAccessPages, setPages);
    const quickAccessContainer = <Container style={{justifyContent: 'center'}}>
        <HebrewLabel font={FONTS.SECULAR_ONE} size="lg">גישה מהירה</HebrewLabel>
        <Row dir="rtl" style={{
            marginTop: '1vh', width: '90%', position: 'relative', left: '5%',
            justifyContent: isMobile ? 'center' : 'space-between'
        }}>
            {quickAccessPages ? quickAccessPages.map(page => <LinkButton text={`ל${page.name}`}
                                                                         link={page.url}/>) : null}
            <LinkButton text="לפורטל" link="https://tikshuv.sharepoint.com/sites/talpiotiaf"/>
        </Row>
    </Container>

    // console.log(ASAK_DATE.toLocaleString())
    // // ASAK counter
    // const ASAKcounterContainer = <Container style={{marginTop: '7vh'}}>
    //     <HebrewLabel font={FONTS.SECULAR_ONE} size="5rem">מ"ג - עוד</HebrewLabel>
    //     <CountDown time={ASAK_DATE}/>
    //     <HebrewLabel font={FONTS.SECULAR_ONE} size="5rem">לקבע</HebrewLabel>
    // </Container>

    // SHORTCUT TABLE
    const [shortcutTable, setShortcuts] = useState(null);
    updateStateVariable('/get_shortcuts_table/', 'shortcut_table', shortcutTable, setShortcuts);
    const shortcutTableContainer = <Container style={{justifyContent: 'right', marginTop: '7vh', direction: 'rtl'}}>
        <HebrewLabel font={FONTS.SECULAR_ONE} size="lg">התקציר שלי</HebrewLabel>
        {shortcutTable ? ComponentSelector(shortcutTable) : null}
    </Container>

    return <Container style={{justifyContent: 'center'}}>
        <HebrewLabel size="3.4vw" font="Guttman stam">
            "כְּמִגְדַּל דָּוִיד צַוָּארֵךְ בָּנוּי לְתַלְפִּיּוֹת,
            {<br/>}
            אֶלֶף הַמָּגֵן תָּלוּי עָלָיו כֹּל שִׁלְטֵי הַגִּבּוֹרִים"
        </HebrewLabel>
        {talpixLogoContainer}
        {quickAccessPages ? quickAccessContainer : null}
        {shortcutTable ? shortcutTableContainer : null}
        {/*{ASAKcounterContainer}*/}
    </Container>
}

export default FrontPage;
import React from 'react';

import ChartComponent, {Chart} from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

const InteractiveChartJsComponent = ({chart, options, width, height,
annotations}) => {

        // For annotations
        const LineAnnotation = ({
                                    borderColor,
                                    borderWidth,
                                    borderDash,
                                    value,
                                    label
                                }) => {


            const createLabel = ({fullText, hint, color, position}) => {
                console.log({fullText, hint, color, position})
                return {
                    borderRadius: 10,
                    borderWidth: 0,
                    backgroundColor: color,
                    display: ctx => ctx.hovered || hint !== '',
                    content: ctx => ctx.hovered ? fullText : hint,
                    position: position
                }
            }
            label.color = borderColor
            return {
                type: 'line',
                borderColor: borderColor,
                borderWidth: borderWidth,
                borderDash: borderDash,
                scaleID: 'x',
                value: value,
                endValue: value,
                drawTime: 'afterDatasetsDraw',
                label: createLabel(label),
                borderCapStyle: 'butt',
                enter(ctx, event) {
                    ctx.hovered = true;
                    ctx.hoverPosition = (event.x / ctx.chart.chartArea.width * 50) + '%';
                    ctx.chart.update();
                },
                leave(ctx, event) {
                    ctx.hovered = false;
                    ctx.chart.update();
                }
            }
        }

        Chart.register(annotationPlugin)

        options.plugins.annotation = {
            annotations: {}

        }
        annotations && annotations.forEach(annotation => {
            options.plugins.annotation.annotations[annotation.name] = LineAnnotation(annotation)
        })

        options.onClick = (event, elements, chart) => {
      if (elements[0]) {
         const i = elements[0].index;
         alert(chart.data.labels[i] + ': ' + chart.data.datasets[0].data[i]);
      }
    }

        return <ChartComponent
            type={chart.type}
            data={chart.data}
            options={options}
            width={width}
            height={height}
            // style={{minWidth: width, minHeight: height}}
        />
    }
;

export default InteractiveChartJsComponent;
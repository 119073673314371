import React, { useEffect } from "react";
import MicrosoftLoginButton from "./MicrosoftLoginButton";
import { checkToIE, getLogger, getScopes, getUserAgentApp } from "./MicrosoftLoginHelpers";

const MicrosoftLogin = ({ graphScopes, clientId, tenantUrl, redirectUri, postLogoutRedirectUri, children, buttonTheme,
                          className, withUserData = false, authCallback, forceRedirectStrategy = false,
                          prompt, debug, useLocalStorageCache }) => {
  const msalInstance = getUserAgentApp({
    clientId,
    tenantUrl,
    redirectUri,
    postLogoutRedirectUri,
    useLocalStorageCache,
  });
  const scopes = getScopes(graphScopes);
  const log = getLogger(debug);

  useEffect(() => {
    msalInstance
      .handleRedirectPromise()
      .then((AuthenticationResult) => {
        if (AuthenticationResult) {
          log(
            "Fetch Azure AD 'token' with redirect SUCCEEDED",
            AuthenticationResult
          );
          log("Fetch Graph API 'access_token' in silent mode STARTED");
          getGraphAPITokenAndUser(true);
        }
      })
      .catch((error) => {
        log("Fetch Azure AD 'token' with redirect FAILED", error, true);
        authCallback(error);
      });
  }, []);

  useEffect(() => {
    const clientToken = useLocalStorageCache
      ? localStorage.getItem("msal.idtoken")
      : sessionStorage.getItem("msal.idtoken");

    clientToken &&
      getGraphAPITokenAndUser(forceRedirectStrategy || checkToIE());
  }, [msalInstance]);

  const login = () => {
    log("Login STARTED");
    if (forceRedirectStrategy || checkToIE()) {
      redirectLogin();
    } else {
      popupLogin();
    }
  };

  const finalStep = (
    AuthenticationResultWithAccessToken
  ) => {
    log(
      "Fetch Graph API 'access_token' SUCCEEDED",
      AuthenticationResultWithAccessToken
    );
    if (withUserData) {
      getUserData(AuthenticationResultWithAccessToken);
    } else {
      log("Login SUCCEEDED");
      authCallback(null, AuthenticationResultWithAccessToken, msalInstance);
    }
  };

  const getGraphAPITokenAndUser = async (isRedirect) => {
    try {
      try {
        const silentRes = await msalInstance.acquireTokenSilent({ scopes });
        finalStep(silentRes);
      } catch (err) {
        log(
          "Fetch Graph API 'access_token' in silent mode is FAILED",
          err,
          true
        );
        if (isRedirect) {
          log("Fetch Graph API 'access_token' with redirect STARTED");
          msalInstance.acquireTokenRedirect({ scopes });
        } else {
          log("Fetch Graph API 'access_token' with popup STARTED");
          const popupRes = await msalInstance.acquireTokenPopup({ scopes });
          finalStep(popupRes);
        }
      }
    } catch (error) {
      log("Login FAILED", error, true);
      authCallback(error);
    }
  };

  const popupLogin = async () => {
    // log("Fetch Azure AD 'token' with popup STARTED");
    try {
      // const AuthenticationResult = await msalInstance.loginPopup({
      //   scopes,
      //   prompt,
      // });
      // log("Fetch Azure AD 'token' with popup SUCCEEDED", AuthenticationResult);
      log("Fetch Graph API 'access_token' in silent mode STARTED");
      getGraphAPITokenAndUser();
    } catch (err) {
      log("Fetch Azure AD 'token' with popup FAILED", err, true);
      authCallback(err);
    }
  };

  const redirectLogin = () => {
    log("Fetch Azure AD 'token' with redirect STARTED");
    msalInstance.loginRedirect({ scopes, prompt });
  };

  const getUserData = async (
    AuthenticationResultWithAccessToken
  ) => {
    const { accessToken } = AuthenticationResultWithAccessToken;
    log("Fetch Graph API user data STARTED");
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await fetch(
      "https://graph.microsoft.com/v1.0/me",
      options
    );
    const userData = await response.json();
    log("Fetch Graph API user data SUCCEEDED", userData);
    log("Login SUCCEEDED");
    authCallback(
      null,
      {
        ...userData,
        ...AuthenticationResultWithAccessToken,
      },
      msalInstance
    );
  };

  return children ? <div onClick={login}>{children}</div>
      : <MicrosoftLoginButton
          buttonTheme={buttonTheme || "light"}
          buttonClassName={className}
          onClick={login}
      />;
};

export default MicrosoftLogin;
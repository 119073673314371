import React from "react";
import './Scroller.css';
import ComponentSelector from "./ComponentSelector";

const Scroller = ({children, time}) => {
    return <div id='header' style={{overflowY: "hidden", height: 'auto', marginTop: "2rem"}}>
        <div className='toScroll' style={{animationDuration: `${time}s`}}>
            {children && children.map(child => {
                if (!child || !child.component)
                    return null;
                const component = ComponentSelector(child.component);
                return <div>{component}</div>
            })}
        </div>
    </div>;
};

export default Scroller;

import React, { useRef, useEffect } from 'react';


const ImageFrom2D = ({ image_table, scale }) => {
    const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    image_table.forEach((row, y) => {
      row.forEach((color, x) => {
        ctx.fillStyle = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
        ctx.fillRect(x * scale, y * scale, scale, scale);
      });
    });
  }, [image_table, scale]);

  const canvasWidth = image_table[0].length * scale;
  const canvasHeight = image_table.length * scale;

  return (
    <canvas
      ref={canvasRef}
      width={canvasWidth}
      height={canvasHeight}
      style={{ maxWidth: '100%', height: 'auto' }}
    />
  );
};

export default ImageFrom2D;
import React, {useState} from "react";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {WIDTHS} from "../constants";

const Toggle = ({onChange, initial_state = false, on_label = '|', off_label = '0', size}) => {
    const [checked, setChecked] = useState(initial_state);
    console.log(WIDTHS[size])
    return <BootstrapSwitchButton
        checked={checked}
        onlabel={on_label}
        offlabel={off_label}
        onChange={(checked) => {
            onChange({checked: checked});
            setChecked(checked);
        }}
        onstyle="outline-primary"
        offstyle="outline-dark"
        width={WIDTHS[size]}
    />
}

export default Toggle;

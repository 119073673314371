import React from 'react';

import {withTheme} from '@rjsf/core';
import {Theme as AntDTheme} from '@rjsf/antd';
import 'antd/dist/antd.css';

import Button from "react-bootstrap/Button";
import FormComboBox from "./form/FormComboBox";

// Make modifications to the theme with your own fields and widgets

const Form = withTheme(AntDTheme);


const JsonSchemaForm = ({json_schema, ui_schema, onSubmit, children = "שליחה"}) => {
  const customWidgets = {SelectWidget: FormComboBox};

  // filters repeated errors
  const transformErrors = (errors) => {
    const res = [];
    const fields_used = [];
    errors.forEach((err) => {
      if (!(fields_used.includes(err.property))) {
        fields_used.push(err.property);
        res.push(err);
      }
    })
    return res;
  }


  return <div>
    <Form
      style={{width: '100%'}}
      schema={json_schema}
      uiSchema={ui_schema}
      widgets={customWidgets}
      onChange={(x) => console.log("changed", x)}
      onSubmit={(x) => onSubmit({data: {properties: x.formData}})}
      onError={(x) => console.log("errors", x)}
      transformErrors={transformErrors}
    >
      <Button type="submit" variant="info">{children}</Button>
    </Form>
    <style type="text/css">
      {`
                .ant-form-item-label {
                    text-align: right !important;
                }
                .ant-select-selector {
                    text-align: right !important;
                }
                .ant-select-item-option-content {
                    text-align: right !important;
                }
                .ant-picker-header {
                    direction: ltr !important;
                }
            `}
    </style>
  </div>;
};

export default JsonSchemaForm;

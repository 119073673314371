import React from 'react';
import {OverlayTrigger, Tooltip as BSTooltip} from "react-bootstrap";
import ComponentSelector from "./ComponentSelector";

const Tooltip = ({tooltipText, children, placement}) => {
    const renderTooltip = () => (
        <BSTooltip id={'tooltip'}>{tooltipText}</BSTooltip>
    );


    return <OverlayTrigger placement={placement} overlay={renderTooltip()} delay={{show: 250, hide: 400}}>
        <div>
            {children && children.map(child => {
                    if (!child || !child.component)
                        return null;
                    const {component: componentData} = child;
                    return ComponentSelector(componentData);
                }
            )
            }
        </div>
    </OverlayTrigger>
}

export default Tooltip;


import React from 'react';

import ChartComponent, {Chart, defaults} from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import {FONTS} from "../constants";

defaults.font.family = FONTS.RUBIK

const ChartJsComponent = ({chart, options, width, height, annotations, onClick}) => {

        // For annotations
        const LineAnnotation = ({
                                    borderColor,
                                    borderWidth,
                                    borderDash,
                                    value,
                                    label
                                }) => {


            const createLabel = ({fullText, hint, color, position}) => {
                console.log({fullText, hint, color, position})
                return {
                    borderRadius: 10,
                    borderWidth: 0,
                    backgroundColor: color,
                    display: ctx => ctx.hovered || hint !== '',
                    content: ctx => ctx.hovered ? fullText : hint,
                    position: position
                }
            }
            label.color = borderColor
            return {
                type: 'line',
                borderColor: borderColor,
                borderWidth: borderWidth,
                borderDash: borderDash,
                scaleID: 'x',
                value: value,
                endValue: value,
                drawTime: 'afterDatasetsDraw',
                label: createLabel(label),
                borderCapStyle: 'butt',
                enter(ctx, event) {
                    ctx.hovered = true;
                    ctx.hoverPosition = (event.x / ctx.chart.chartArea.width * 50) + '%';
                    ctx.chart.update();
                },
                leave(ctx, event) {
                    ctx.hovered = false;
                    ctx.chart.update();
                }
            }
        }

        Chart.register(annotationPlugin)

        options.plugins.annotation = {
            annotations: {}

        }

        // Group annotations
        let annotationsGrouped = {}
        annotations && annotations.forEach(annotation => {
            if (!(annotation.value in annotationsGrouped))
                annotationsGrouped[annotation.value] = []

            annotationsGrouped[annotation.value].push(annotation)
        });

        Object.entries(annotationsGrouped).forEach(annotGroup => {
            const [group, annotations] = annotGroup
            const hint = annotations[0].label.hint
            const desc = []
            annotations.forEach(annot => {
                desc.push(annot.label.fullText)
            })

            const borderColor = annotations[0].borderColor
            const borderWidth = annotations[0].borderWidth
            const borderDash = annotations[0].borderDash
            const value = group
            const label = {
                fullText: desc,
                hint: hint,
                position: annotations[0].label.position
            }
            options.plugins.annotation.annotations[`annot${group}`] = LineAnnotation({
                borderColor: borderColor,
                borderWidth: borderWidth,
                borderDash: borderDash,
                value: value,
                label: label
            })
        })


        options.layout = {
            ...options.layout,
            padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
            }
        }

        const onPointClick = async (point, dataset) => {
            onClick({data: {point: point, dataset: dataset}})
        }

        options = {
            ...options,
            onClick: (evt, elements, chart) => {
                let point = chart.getElementsAtEventForMode(evt, 'point', {intersect: true}, true)[0]
                if (point != null) {
                    let point_idx = point.index
                    let dataset_idx = point.datasetIndex

                    // call the function on the server
                    onPointClick(point_idx, dataset_idx)
                }
            }
        }

        return <ChartComponent
            type={chart.type}
            data={chart.data}
            options={options}
            width={width}
            height={height}
        />
    }
;

export default ChartJsComponent;
import React from "react";

const EmbeddedWindow = ({url, max_width}) => {

    return (
        <iframe
            src={url}
            width={max_width}
            frameborder="0"
            allowfullscreen=""
            style={{
                maxWidth: '100%',
                aspectRatio: '16/9',
            }}>
        </iframe>
    );
}

export default EmbeddedWindow;

import React from 'react';
import SingleSelectFormComboBox from "./SingleSelectFormComboBox";
import MultiSelectFormComboBox from "./MultiSelectFormComboBox";

const FormComboBox = ({multiple, ...props}) => {
  console.log(props);
  if (multiple === true) {
    return <MultiSelectFormComboBox multiple={true} {...props}/>
  }
  else {
    return <SingleSelectFormComboBox multiple={false} {...props}/>
  }
};

export default FormComboBox;

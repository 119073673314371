import React from "react";
import {COLORS, FONTS, SIZES} from "../constants";

const TextInput = ({onChange, font = FONTS.RUBIK, size = 'md',
                       bold = false, italic = false, placeholder = "",
                       background_color = COLORS.NONE, text_color = COLORS.BLACK, width = '100%'
                   }) => {


    const onSubmit = async (inp) => {
        let val = inp.target.value;
        if (val === "") {
            val = " ";
        }
        onChange({data: val});
    };

    const styledLabel = {
        fontSize: SIZES[size] || size,
        fontFamily: `${font}, serif`,
        fontWeight: bold ? 'bold' : 'normal',
        fontStyle: italic ? 'italic' : 'normal',
        color: text_color,
        background: background_color,
        margin: 0,
        padding: 0,
        width: width,
        whiteSpace: 'pre-line',
        border: '1px solid #ccc',
        borderRadius: '5px'
    }

    return <input type="text" placeholder={placeholder} onBlur={onSubmit} style={styledLabel} dir="rtl"/>;
};
export default TextInput;

import React from "react";

import ImageFrom2D from "./ImageFrom2D";
import Button from "./Button";
import HebrewLabel from "./HebrewLabel";
import Form from "./Form";
import JsonSchemaForm from "./JsonSchemaForm";
import Table from "./Table";
import PopUp from "./PopUp";
import GridPanel from "./GridPanel";
import ComboBox from "./ComboBox";
import {pageContext} from "../stores/pageContext";
import StackPanel from "./StackPanel";
import ChartJsComponent from './ChartJsComponent';
import InteractiveChartJsComponent from './InteractiveChartJsComponent'
import {FONTS} from "../constants";
import YnetComponent from "./YnetComponent";
import Image from "./Image";
import Toggle from "./Toggle";
import Slideshow from "./Slideshow";
import FileUpload from "./FileUpload";
import FileDisplay from "./FileDisplay";
import Scroller from "./Scroller";
import HyperLink from "./HyperLink";
import Accordion from "./Accordion";
import CodeBlock from "./CodeBlock";
import CodeEditor from "./CodeEditor";
import FileTree from "./FileTree";
import ViewLogs from "./ViewLogs";
import CountDown from "./CountDown";
import Markdown from "./Markdown";
import GoogleDocsDisplay from "./GoogleDocsDisplay";
import DownloadButton from "./DownloadButton";
import Tab from "./Tab";
import Card from './Card'
import GridView from "./GridView";
import Container from './Container'
import Tooltip from "./Tooltip";
import FloatingTabView from "./FloatingTabView";
import RichTextEditor from "./RichTextEditor";
import CircularProgress from "./CircularProgress";
import TalpiotGradePicker from "./TalpiotGradePicker";
import TextInput from "./TextInput";
import PureHTML from "./PureHTML";
import TableForm from "./TableForm";
import EmbeddedWindow from "./EmbeddedWindow";
import EmbeddedYouTubeVideo from "./EmbeddedYouTubeVideo";
import WSListener from "./WSListener";
import SignatureComponent from "./SignatureComponent";


// return the component by its type
const ComponentSelector = ({type, text, action, id, ...props}) => {
  return (<pageContext.Consumer>
    {
      ({onEvent}) => {
        if (type === 'Label')
          return <HebrewLabel font={FONTS.RUBIK} color={props.text_color} {...props}>{text}</HebrewLabel>
        if (type === 'Button')
          return <Button text={text} color={props.text_color} onClick={() => onEvent(action, {})} {...props}/>
        if (type === 'Toggle')
          return <Toggle onChange={(data) => onEvent(action, data)} {...props}/>
        if (type === 'Form')
          return <Form onSubmit={(data) => onEvent(action, data)} props={props}>{text}</Form>
        if (type === 'JsonSchemaForm')
          return <JsonSchemaForm onSubmit={(data) => onEvent(action, data)} {...props}>{text}</JsonSchemaForm>
        if (type === 'DataGrid')
          return <Table onSubmit={(data) => onEvent(action, data)} {...props}>{text}</Table>
        if (type === 'TableForm')
          return <TableForm onSubmit={(data) => onEvent(action, data)} text={text} {...props}/>
        if (type === 'PopUp')
          return <PopUp onEvent={() => onEvent(action, {})} props={props}/>
        if (type === 'GridPanel')
          return <GridPanel {...props}/>;
        if (type === 'ComboBox')
          return <ComboBox placeHolder={text} onChoose={(body) => onEvent(action, body)} {...props} />
        if (type === 'StackPanel')
          return <StackPanel {...props} />
        if (type === 'ChartJsComponent')
          return <ChartJsComponent {...props} />
        if (type === 'InteractiveChartJsComponent')
          return <InteractiveChartJsComponent {...props} />
        if (type === 'image')
          return <Image {...props} />
        if (type === 'scroller')
          return <Scroller text={text} children={props.children} {...props}/>
        if (type === 'YnetComponent')
          return <YnetComponent/>
        if (type === 'Slideshow')
          return <Slideshow {...props}/>
        if (type === 'Tab')
          return <Tab {...props}/>
        if (type === 'UploadFile')
          return <FileUpload uploadFiles={(data) => onEvent(action, data)} {...props} />
        if (type === 'DisplayFile')
          return <FileDisplay {...props} onClick={() => onEvent(action, {})}/>
        if (type === 'HyperLink')
          return <HyperLink {...props} >{text}</HyperLink>
        if (type === 'Accordion')
          return <Accordion {...props} />
        if (type === 'CodeBlock')
          return <CodeBlock text={text} {...props} />
        if (type === 'CodeEditor')
          return <CodeEditor text={text} onSubmitCode={(data) => onEvent(action, data)} {...props} />
        if (type === 'RichTextEditor')
          return <RichTextEditor onSave={(data) => onEvent(action, data)} {...props} />
        if (type === 'TextInput')
          return <TextInput placeholder={text} onChange={(data) => onEvent(action, data)} {...props}/>
        if (type === 'TalpiotGradePicker')
          return <TalpiotGradePicker onChoose={(data) => onEvent(action, data)} {...props} />
        if (type === 'CircularProgress')
          return <CircularProgress {...props} />
        if (type === 'FileTree')
          return <FileTree onFileClick={(data) => onEvent(action, data)} {...props} />
        if (type === 'LogViewer')
          return <ViewLogs onUpdate={(data) => onEvent(action, data)} {...props} />
        if (type === 'DownloadButton')
          return <DownloadButton text={text} onDownload={() => onEvent(action, null, true)} {...props} />
        if (type === 'CountDown')
          return <CountDown {...props} />
        if (type === 'Markdown')
          return <Markdown text={text} {...props} />
        if (type === 'Divider')
          return <div style={{margin: '7px 0', width: '32vw', borderTop: '1px solid rgba(0, 0, 0, 0.15)'}}/>
        if (type === 'GoogleDocsDisplay')
          return <GoogleDocsDisplay {...props}/>
        if (type === 'Card')
          return <Card {...props}/>
        if (type === 'GridView')
          return <GridView {...props}/>
        if (type === 'Container')
          return <Container {...props}/>
        if (type === 'PureHTML')
          return <PureHTML {...props}/>
        if (type === 'Tooltip')
          return <Tooltip {...props}/>
        if (type === 'FloatingTabView')
          return <FloatingTabView {...props}/>
        if (type === 'ImageFrom2D')
          return <ImageFrom2D {...props}/>
        if (type === 'EmbeddedWindow')
          return <EmbeddedWindow {...props}/>
        if (type === 'EmbeddedYouTubeVideo')
          return <EmbeddedYouTubeVideo {...props}/>
        if (type === 'wsListener')
          return <WSListener onUpdate={() => onEvent(action, {})} id={id} {...props}/>
        if (type === "signatureComponent")
          return <SignatureComponent onSign={(data) => onEvent(action, data)} id={id} {...props}/>
      }
    }
  </pageContext.Consumer>);
};

export default ComponentSelector;

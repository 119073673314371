import React from 'react';
import ComponentSelector from "./ComponentSelector";
import {useMediaQuery} from "react-responsive";

const GRID_GAP = '1rem'
const GRID_MARGIN = '0 auto'

const GridView = ({children}) => {
    const isPortrait = useMediaQuery({query: '(orientation: portrait)'})
    const isMobile = useMediaQuery({query: '(max-width: 650px)'})
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1024px)'})
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1440px)'})

    // Handles responsibility
    let cellWidthResponsible = () => {
        if (isTabletOrMobile) {
            if (isPortrait) {
                return 300;
            } else if (!isMobile && !isPortrait) {
                return 400;
            }
        } else if (isDesktopOrLaptop) {
            return 500;
        } else {
            return 300;
        }
    }

    let style = {
        display: 'grid',
        margin: GRID_MARGIN,
        gridTemplateColumns: `repeat(auto-fit, minmax(${cellWidthResponsible()}px, 1fr))`,
        gridColumnGap: GRID_GAP,
        gridRowGap: GRID_GAP,
        gridAutoFlow: 'dense'
    }

    return <div style={style}>
        {children && children.map(child => {
                if (!child || !child.component)
                    return null;
                const {component: componentData} = child;
                return ComponentSelector(componentData);
            }
        )}
    </div>
}

export default GridView;

